/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const getRedirectAbTest = (list) => {
    const redirectTests = [];
    if (list) {
        (Object.keys(list || {}) || []).forEach((test) => {
            // getting all test which is starting with redirect_
            if (test?.indexOf?.('redirect_') === 0) {
                redirectTests.push(list?.[test]);
            }
        });
    }
    return redirectTests;
};

export const redirectAbTesting = ({ history, redirectAbTest, pathname }) => {
    const availableRedirect = (redirectAbTest || []).find?.((test) => test?.type === 'variant' && pathname === test?.from) || null;
    if (availableRedirect?.to) {
        history.replace(availableRedirect.to);
    }
};

export default {};
