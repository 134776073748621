/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object, number } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Media from 'react-media';
import SkeletonStyles from '../SkeletonStyles';
import CUSTOM_BREAKPOINTS from '../../../../helpers/customBreakpoints';
import TabletCategoryPageSkeleton from './TabletCategoryPageSkeleton';

const styles = (theme) => ({
    // Import Skeleton layout
    ...SkeletonStyles,

    // Page Elements
    pageWrapper: {
        margin: '0 auto',
        width: '100%',
        maxWidth: '1405px',
    },
    underNavext: {
        width: '100%',
        height: '60px',
    },
    imoc: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 0 0 0',
        '&--title': {

        },
        '&--divider': {
            position: 'relative',
            width: '20px',
            height: '40px',
        },
        '&--copy': {
            width: '99%',
        },
    },
    subNav: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, auto)',
        gridGap: '4px',
        margin: '35px 0 65px 0',
        '&--link': {
            height: '40px',
        },
    },
    productGrid: {
        display: 'grid',
        gridTemplateColumns: '19% auto auto auto',
        gridGap: '20px',
        '&--left-block': {
            height: '300px',
            [theme?.breakpoints?.down('md')]: {
                height: '270px',
            },
        },
        '&--product-block': {
            paddingBottom: '109.565217%',
            [theme?.breakpoints?.down('md')]: {
                paddingBottom: '109.565217%',
            },
        },
        '&--product-title': {
            width: '40%',
        },
        '&--product-price': {
            width: '22%',
        },
        '&--product-delivery': {
            width: '31%',
            marginBottom: '20px',
        },
    },
});

const GraphqlCategorySkeleton = ({ classes, breakpoint }) => {
    const breakpointExpr =  `(max-width: ${(breakpoint || CUSTOM_BREAKPOINTS.tabPortrait)}px)`;

    return (
        <>
            <Media
                query={breakpointExpr}
            >
                {(matches) => (
                    matches
                        ? (
                            <TabletCategoryPageSkeleton />
                        )
                        :  (
                            <div>
                                <div className={`${classes.underNavext} ${classes.shimmer}`} />
                                <div className={classes.pageWrapper}>
                                    <div className={`${classes.container} ${classes.imoc}`}>
                                        <div className={`${classes.textLG} ${classes.shimmer}`} />
                                        <div className={`${classes.imoc}--divider`} />
                                        <div className={`${classes.imoc}--copy`}>
                                            <div className={classes.textFW} />
                                            <div className={classes.textSF} />
                                        </div>
                                    </div>
                                    <div className={classes.subNav}>
                                        <div className={`${classes.subNav}--link ${classes.textFW} ${classes.shimmer}`} />
                                        <div className={`${classes.subNav}--link ${classes.textFW} ${classes.shimmer}`} />
                                        <div className={`${classes.subNav}--link ${classes.textFW} ${classes.shimmer}`} />
                                        <div className={`${classes.subNav}--link ${classes.textFW} ${classes.shimmer}`} />
                                    </div>
                                    <div className={classes.productGrid}>
                                        <div>
                                            <div className={`${classes.productGrid}--left-block ${classes.textFW} ${classes.shimmer}`} />
                                            <div className={`${classes.productGrid}--left-block ${classes.textFW} ${classes.shimmer}`} />
                                            <div className={`${classes.productGrid}--left-block ${classes.textFW} ${classes.shimmer}`} />
                                            <div className={`${classes.productGrid}--left-block ${classes.textFW} ${classes.shimmer}`} />
                                        </div>
                                        <div>
                                            <div>
                                                <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                                                <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                                                <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                                                <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
                                            </div>
                                            <div>
                                                <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                                                <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                                                <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                                                <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                                                <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                                                <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                                                <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
                                            </div>
                                            <div>
                                                <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                                                <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                                                <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                                                <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                                                <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                                                <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                                                <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
                                            </div>
                                            <div>
                                                <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                                                <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                                                <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                                                <div className={`${classes.productGrid}--product-delivery ${classes.textSM} ${classes.shimmer}`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                )}
            </Media>
        </>
    );
};

GraphqlCategorySkeleton.propTypes = {
    classes: object.isRequired,
    breakpoint: number,
};
GraphqlCategorySkeleton.defaultProps = {
    breakpoint: CUSTOM_BREAKPOINTS.tabPortrait,

};

export default withStyles(styles)(GraphqlCategorySkeleton);
