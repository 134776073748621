/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useContext, useEffect } from 'react';
import StyleLinkContext from './StyleLinkContext';

// To detect if it's in SSR process or in browser. Wrapping with
// the function makes rollup's replacement of "this" avoidable
const isBrowser = (() => this && typeof this.window === 'object')();

function useStyleLinkTag(url) {
    const { insertLinkTag } = useContext(StyleLinkContext);
    if (!insertLinkTag) throw new Error('Please provide "insertLinkTag" function by StyleLinkContext.Provider');
    const runEffect = () => {
        const removeLink = insertLinkTag(url);
        return () => {
            setTimeout(removeLink, 0);
        };
    };
    if (isBrowser) {
        useEffect(runEffect, []);
    } else {
        runEffect();
    }
}

export default useStyleLinkTag;
