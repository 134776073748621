/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import loadable from '@loadable/component';
import SCIContainer from '../app/components/GraphqlComponents/SCI/SCIContainer';

const LoadableSocialAndEnvironmentPractices = loadable(() => import(/* webpackChunkName: "SocialAndEnvironmentPractices" */ '../app/components/GraphqlComponents/SocialAndEnvironmentPractices/SocialAndEnvironmentPracticesBlock'));
const LoadableOurFamilyOfBrands = loadable(() => import(/* webpackChunkName: "OurFamilyOfBrands" */ '../app/components/GraphqlComponents/OurFamilyOfBrands/OurFamilyOfBrandsBlock'));
const LoadableInternationalDeliveryPage = loadable(() => import(/* webpackChunkName: "InternationalDeliveryPage" */ '../app/components/GraphqlComponents/InternationalDeliveryPage/InternationalFlowerDeliveryPage'));
const LoadablePassportPageContainer = loadable(() => import(/* webpackChunkName: "PassportPageContainer" */ '../app/components/GraphqlComponents/PassportPage/PassportPage'));
const LoadableCelebrationsRewardsPage = loadable(() => import(/* webpackChunkName: "CelebrationsRewardsPage" */ '../app/components/GraphqlComponents/CelebrationsRewardsPage/CelebrationsRewardsPage'));
const LoadableFlowerClubsPage = loadable(() => import(/* webpackChunkName: "FlowerClubsPage" */ '../app/components/GraphqlComponents/FlowerClubsPage/FlowerClubsPage'));
const LoadableMessageFromCeoPage = loadable(() => import(/* webpackChunkName: "MessageFromCeoPage" */ '../app/components/GraphqlComponents/MessageFromCeoPage/MessageFromCeoPage'));
const LoadableMothersDayArticleGiftIdeasPage = loadable(() => import(/* webpackChunkName: "MothersDayArticleGiftIdeasPage" */ '../app/components/GraphqlComponents/MothersDayArticleGiftIdeasPage/MothersDayArticleGiftIdeasPage'));
const LoadableCelebrationsMothersDayPage = loadable(() => import(/* webpackChunkName: "CelebrationsMothersDayPage" */ '../app/components/GraphqlComponents/CelebrationsMothersDayPage/CelebrationsMothersDayPage'));
const LoadableMothersDayArticleTrendingFlowersPage = loadable(() => import(/* webpackChunkName: "MothersDayArticleTrendingFlowersPage" */ '../app/components/GraphqlComponents/MothersDayArticleTrendingFlowersPage/MothersDayArticleTrendingFlowersPage'));
const LoadableGiftForPetLoverMomPage = loadable(() => import(/* webpackChunkName: "GiftForPetLoverMomPage" */ '../app/components/GraphqlComponents/GiftForPetLoverMomPage/GiftForPetLoverMomPage'));
const LoadableMomsWhoWowSweepstakesPage = loadable(() => import(/* webpackChunkName: "MomsWhoWowSweepstakesPage" */ '../app/components/GraphqlComponents/MomsWhoWowSweepstakesPage/MomsWhoWowSweepstakesPage'));
const LoadableCustomerServiceFaqCovid = loadable(() => import(/* webpackChunkName: "CustomerServiceFaqCovid" */ '../app/components/GraphqlComponents/CustomerServicePage/CustomerServiceFaqCovid/CustomerServiceFaqCovid'));
const LoadableGraphqlRecipeCategoryLanderPage = loadable(() => import(/* webpackChunkName: "GraphqlRecipeCategoryLanderPage" */ '../app/components/GraphqlComponents/GraphqlRecipePage/GraphqlRecipeCategoryLanderPage'));
const LoadableCustomerServiceConfirmPage = loadable(() => import(/* webpackChunkName: "CustomerServiceConfirmPage" */ '../app/components/GraphqlComponents/CustomerService/CustomerServiceConfirmPage/CustomerServiceConfirmPage'));
const LoadableCustomerServiceOrderPage = loadable(() => import(/* webpackChunkName: "CustomerServiceOrderPage" */ '../app/components/GraphqlComponents/CustomerService/CustomerServiceOrderPage/CustomerServiceOrderPage'));
const LoadableCustomerServiceDifferentIssuePage = loadable(() => import(/* webpackChunkName: "CustomerServiceDifferentIssuePage" */ '../app/components/GraphqlComponents/CustomerService/CustomerServiceDifferentIssuePage/CustomerServiceDifferentIssuePage'));
const LoadableCustomerServiceCancelOrderPage = loadable(() => import(/* webpackChunkName: "CustomerServiceCancelOrderPage" */ '../app/components/GraphqlComponents/CustomerService/CustomerServiceCancelOrderPage/CustomerServiceCancelOrderPage'));
const LoadableCustomerServiceAgentPage = loadable(() => import(/* webpackChunkName: "CustomerServiceAgentPage" */ '../app/components/GraphqlComponents/CustomerService/CustomerServiceAgentPage/CustomerServiceAgentPage'));
const LoadableCustomerServiceMyOrderPage = loadable(() => import(/* webpackChunkName: "CustomerServiceMyOrderPage" */ '../app/components/GraphqlComponents/CustomerService/CustomerServiceMyOrderPage/CustomerServiceMyOrderPage'));
const LoadableCustomerServiceFlowersPage = loadable(() => import(/* webpackChunkName: "CustomerServiceFlowersPage" */ '../app/components/GraphqlComponents/CustomerService/CustomerServiceFlowersPage/CustomerServiceFlowersPage'));
const LoadableCitiPointsTermsOfUse = loadable(() => import(/* webpackChunkName: "CitiPointsTermsOfUse" */ '../app/components/GraphqlComponents/CitiPointsTermsOfUse/CitiPointsTermsOfUse'));
const LoadableKristinaLibbyGalleryPage = loadable(() => import(/* webpackChunkName: "KristinaLibbyGalleryPage" */ '../app/components/GraphqlComponents/KristinaLibbyGalleryPage/KristinaLibbyGalleryPage'));
const LoadableKristinaLibbyMapLandingPage = loadable(() => import(/* webpackChunkName: "KristinaLibbyMapLandingPage" */ '../app/components/GraphqlComponents/KristinaLibbyMapLandingPage/KristinaLibbyMapLandingPage'));
const LoadableAgentSubscriptionMgmtPage = loadable(() => import(/* webpackChunkName: "AgentSubscriptionMgmtPage" */  '../app/pages/AgentSubscriptionMgmt/AgentSubscriptionMgmt')); // SubscriptionMgmtPage
const LoadableSubscriptionCancellationPage = loadable(() => import(/* webpackChunkName: "SubscriptionCancellationPage" */  '../app/pages/AgentSubscriptionMgmt/components/SubscriptionCancellationPage'));
const LoadablePassportYotpoSocialLandingPage = loadable(() => import(/* webpackChunkName: "PassportYotpoSocialLandingPage" */  '../app/components/GraphqlComponents/PassportPage/PassportYotpoSocialLandingPage'));
const LoadableChooseItemPage = loadable(() => import(/* webpackChunkName: "SubscriptionChooseItemPage" */  '../app/components/GraphqlComponents/SubscriptionPageV2/ChoosePlanPage/ChoosePlanPage'));
const LoadableSelfAttestationForm = loadable(() => import(/* webpackChunkName: "Account" */ '../app/components/GraphqlComponents/MarketplacePage/Partials/SelfAttestationForm/FormContainer'));
const LoadableFlowerGiftsPage = loadable(() => import(/* webpackChunkName: "FlowerGiftsPage" */ '../app/components/GraphqlComponents/FlowerGiftsPage/FlowerGiftsPage'));
export const contentPageRoutes = [
    {
        path: '/fhdirect',
        Component: SCIContainer,
        // condition: page => page.search.includes('LocNumber'),
    },
    {
        path: '/international-flower-delivery',
        Component: LoadableInternationalDeliveryPage,
    },
    {
        path: '/kristina-libby-flower-heart-images',
        Component: LoadableKristinaLibbyGalleryPage,
    },
    {
        path: '/flowers-gifts-13259',
        Component: LoadableFlowerGiftsPage,
    },
    {
        path: '/celebrations-rewards',
        Component: LoadableCelebrationsRewardsPage,
    },
    {
        path: '/social-and-environmental-practices',
        Component: LoadableSocialAndEnvironmentPractices,
    },
    {
        path: '/our-family-of-brands',
        Component: LoadableOurFamilyOfBrands,
    },

    {
        path: '/flower-clubs',
        Component: LoadableFlowerClubsPage,
    },

    {
        path: '/a-message-to-our-customers-400216497',
        Component: LoadableMessageFromCeoPage,
    },
    {
        path: '/articles/mday/mothers-day-gift-ideas',
        Component: LoadableMothersDayArticleGiftIdeasPage,
    },
    {
        path: '/celebrations-mothers-day-gift-ideas',
        Component: LoadableCelebrationsMothersDayPage,
    },
    {
        path: '/articles/mday/top-trending-mother-s-day-flowers',
        Component: LoadableMothersDayArticleTrendingFlowersPage,
    },
    {
        path: '/articles/mday/gifts-for-the-pet-lover-mom',
        Component: LoadableGiftForPetLoverMomPage,
    },
    {
        path: '/moms-who-wow-sweepstakes',
        Component: LoadableMomsWhoWowSweepstakesPage,
    },

    {
        path: '/customer-service-faq-covid',
        Component: LoadableCustomerServiceFaqCovid,
    },

    {
        path: '/customer-service-confirm',
        Component: LoadableCustomerServiceConfirmPage,
    },
    {
        path: '/customer-service-order',
        Component: LoadableCustomerServiceOrderPage,
    },

    {
        path: '/customer-service-different-issue',
        Component: LoadableCustomerServiceDifferentIssuePage,
    },
    {
        path: '/customer-service-cancel',
        Component: LoadableCustomerServiceCancelOrderPage,
    },

    {
        path: '/customer-service-agent',
        Component: LoadableCustomerServiceAgentPage,
    },
    {
        path: '/customer-service-my-order',
        Component: LoadableCustomerServiceMyOrderPage,
    },
    {
        path: '/customer-service-flowers',
        Component: LoadableCustomerServiceFlowersPage,
    },
    {
        path: '/citi-points-terms-of-use',
        Component: LoadableCitiPointsTermsOfUse,
    },
    {
        path: '/passport-berries',
        Component: LoadablePassportPageContainer,
    },
    {
        path: '/passport-fruit',
        Component: LoadablePassportPageContainer,
    },
    {
        path: '/floral-heart-project-locations',
        Component: LoadableKristinaLibbyMapLandingPage,
    },
    {
        path: '/agent-subscription-management',
        Component: LoadableAgentSubscriptionMgmtPage,
    },
    {
        path: '/subscription-management/subscription-details',
        Component: LoadableSubscriptionCancellationPage,
    },
    {
        path: '/passport-social-rewards-program',
        Component: LoadablePassportYotpoSocialLandingPage,
    },
    {
        path: '/subscriptions/customizable-plan/choose-item',
        Component: LoadableChooseItemPage,
    },
    {
        path: '/marketplace-diversity-inclusion-program-attestation-form',
        Component: LoadableSelfAttestationForm,
    },
];

export const isContentPageRoute = ({ page }) => contentPageRoutes.filter((route) => route.path === page.path).length;
export const getCurrRecipeName = (recipeName) => {
    const data = {
        path: `/h/recipes/${recipeName}`,
        Component: LoadableGraphqlRecipeCategoryLanderPage,
    };
    contentPageRoutes.push(data);
};
export default {};
