/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { string, object } from 'prop-types';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';
import findContent from '../../../../gql/queries/findContent';
import FrequentlyAskedQuestionsUI from './FrequentlyAskedQuestionsUI';
import { getBrand } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const FrequentlyAskedQuestions = ({ queryTitle, brand }) => {
    const Question_Answers = findContent({
        brand: brand.domain,
        contentType: 'question_answer_list',
        query: `\\"title\\" : \\"${queryTitle}\\"`,
    });
    const { data, error, loading } = useQuery(Question_Answers);
    if (loading) {
        return <div style={{ height: '170px' }} />;
    }

    if (error) {
        return null;
    }

    if (data?.findContent?.content?.entries?.length === 0) {
        return null;
    }
    const faqData = data.findContent.content.entries[0];

    if (faqData) {
        return <FrequentlyAskedQuestionsUI faqData={faqData} />;
    }
    return null;
};

FrequentlyAskedQuestions.propTypes = {
    queryTitle: string.isRequired,
    brand: object.isRequired,
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
});
export default connect(mapStateToProps)(FrequentlyAskedQuestions);
