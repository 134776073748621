/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { emitCustomTrackingEvent } from '../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';

/**
 *
 * @param {object} salesforceResponse - response from salesforce api
 * @param {boolean} isFireImpression - To fire campaign impression
 * @param {object} page - clickstream page section
 * @returns {trackClickThrough} trackClickThrough - Used to fire clickthrough when product is clicked.
 */
const useClickStreamCustomEventsTracking = ({ salesforceResponse, isFireImpression, page }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (isFireImpression && salesforceResponse) {
            dispatch(emitCustomTrackingEvent({
                action: 'Impression',
                salesforceResponse,
                page,
                eventSubType: 'campaign-tracking',
            }));
        }
    }, [salesforceResponse]);

    const trackClickThrough = (partNumber, productFromControl) => {
        if (salesforceResponse) {
            dispatch(emitCustomTrackingEvent({
                action: 'Click',
                partNumber,
                productFromControl,
                salesforceResponse,
                page,
                eventSubType: 'campaign-tracking',
            }));
        }
    };

    return trackClickThrough;
};

export default useClickStreamCustomEventsTracking;
