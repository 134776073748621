/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { gql } from '@apollo/client';
import { GRAPHQL_ENV } from '..';

/**
 * @param {string} brand - flowers or food
 * @param {string} path - path to be fetched
 */

// The uid is used for cache invalidation, but it must be requested to get used as a header
// the regex is used to strip out all newline characters. If one appears and the regex is not present the app crashes.
const findURL = (brand, path) => (
    gql`{ findURL(brand: "${brand}", locale: "en-us", environment: "${GRAPHQL_ENV}", path: "${path.replace(/\r?\n|\r/g, '')}") {
            contentType
            type
            commerceURL
            code
            uid
            vanity {
                url
                redirectType
                bannerCode
                __typename
            }
            bannerCode {
                content
                __typename
            }
            __typename
        }
    }`
);

export default findURL;
