/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import mbpLogger from 'mbp-logger';
import QueryString from 'qs';
import { Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { GRAPHQL_ENV } from '../../../gql';
import { getBrand } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getSCIData, setSCILoadingState } from '../../../../state/ducks/SCI/SCI-Actions';

import CategoryPageSkeleton from '../GraphqlSkeletonComponents/CategoryPageSkeleton/CategoryPageSkeleton';

const SCIContainer = ({
    brand,
    actionSCIData,
    history,
    location: {
        search,
    },
    actionSetSCILoadingState,
}) => {
    const urlParams = search?.slice(1) || null;
    const mId = QueryString.parse(urlParams).mId;
    const locNumber = QueryString.parse(urlParams).LocNumber;
    const referrer = QueryString.parse(urlParams).referrer;
    const utm_term = QueryString.parse(urlParams).campaign_id;
    const Find_Banner_Code_Query = gql`
        query findBannerCodeQuery {
            findBannerCode(brand: "${brand['domain-name']}", environment: "${GRAPHQL_ENV}", locale: "en-us", code: "${locNumber}") {
                content
            }
        }
    `;

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex,nofollow" />
            </Helmet>
            <Query query={Find_Banner_Code_Query}>
                {({ loading, data, error }) => {
                    if (loading) {
                        actionSetSCILoadingState(true);
                        return <CategoryPageSkeleton />;
                    }

                    if (error) {
                        mbpLogger.logError({
                            appName: process.env.npm_package_name,
                            component: 'SCIContainer.js',
                            message: 'Error loading data from Graphql',
                            env: GRAPHQL_ENV,
                            error,
                            urlParams: search,
                        });
                        return null;
                    }

                    if (!data) {
                        mbpLogger.logError({
                            appName: process.env.npm_package_name,
                            component: 'SCIContainer.js',
                            message: 'No data returned for query',
                            env: GRAPHQL_ENV,
                            urlParams: search,
                        });
                        return null;
                    }

                    // check url params & cs data
                    // if params missing redirect to homepage
                    if (urlParams && data) {
                        const bannerCodeData = data?.findBannerCode?.content?.entries?.[0];

                        if (!bannerCodeData) {
                            mbpLogger.logError({
                                appName: process.env.npm_package_name,
                                message: 'no SCI data returned from CMS',
                                component: 'SCIContainer.js',
                                locNumber,
                                urlParams: search,
                            });
                        }

                        actionSCIData(mId, referrer, utm_term, history, bannerCodeData, locNumber);
                        return <CategoryPageSkeleton />;
                    }

                    return (
                        <Route>
                            <Redirect to="/" />
                        </Route>
                    );
                }}
            </Query>
        </>
    );
};

SCIContainer.propTypes = {
    actionSCIData: func.isRequired,
    brand: object.isRequired,
    history: object.isRequired,
    location: object.isRequired,
    actionSetSCILoadingState: func.isRequired,
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
});

const mapDispatchToProps = (dispatch) => ({
    actionSCIData: bindActionCreators(getSCIData, dispatch),
    actionSetSCILoadingState: bindActionCreators(setSCILoadingState, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SCIContainer));
