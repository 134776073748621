/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useState } from 'react';
import { uid } from 'react-uid';
import QueryString from 'qs';
import  Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { string, shape, array } from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link, useLocation } from 'react-router-dom';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactMarkdown from 'react-markdown';
import ExpansionPanelDetails  from '@material-ui/core/ExpansionPanelDetails';
import ReactHTMLParser from 'react-html-parser';
import GraphqlSeoHeadTags from '../../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';

const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 980,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 980,
            margin: '0 auto',
            padding: 50,
            border: `1px solid ${theme.palette.grey10}`,
        },
    },
    breadCrumb: {
        marginTop: 10,
        '& a': {
            color: theme.palette.common.black,
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            fontSize: 15,
            outline: 'none',
        },
    },
    panelDescription: {
        borderBottom: `1px solid ${theme.palette.grey10}`,

    },
    contentQuestion: {
        '& h3': {
            display: 'block',
        },
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    header: {
        '& h1': {
            textAlign: 'center',
            fontWeight: 'normal',
            fontSize: 40,
        },
    },
}));
const FrequentlyAskedQuestionsUI = ({ faqData }) => {
    const urlParams = useLocation().search?.slice(1) || '';
    const activeTab = QueryString.parse(urlParams)?.['active-tab'] || '';

    const [expandedPanelLabel, setExpandedPanelLabel] = useState(activeTab);

    const handlePanelPress = (panelLabel) => (event, isExpanded) => {
        setExpandedPanelLabel(isExpanded ? panelLabel : '');
    };

    const classes = useStyles(faqData);
    const title = faqData?.title;
    const seo = faqData?.seo ?? {};

    const urlPanelLabel = {
        panel_0: 'covid-19Updates',
        panel_1: 'customerService',
        panel_2: 'billing',
        panel_3: 'orderStatus',
        panel_4: 'shippingAndDelivery',
        panel_5: 'celebrationsPassport',
        panel_6: 'allergyWarning',
    };

    const linkOrReference = ({ href, children }) => <a href={href}>{children}</a>;

    return (
        <>
            <GraphqlSeoHeadTags seoData={seo} />
            <div className={classes.container}>
                <div className={classes.breadCrumb}><Link to="/customer-service"><ArrowBackIosIcon className={classes.arrowSign} />Help Center</Link></div>
                <Grid className={classes.header} item xs={12}>
                    <div className={classes.title}>
                        <div className={classes.faqtitle}><h1>{title}</h1></div>
                    </div>
                </Grid>
                {faqData?.categories?.length && faqData.categories?.map((d, panelIndex) => (
                    <ExpansionPanel
                        key={d.category.category_title}
                        expanded={expandedPanelLabel === urlPanelLabel[`panel_${panelIndex}`]}
                        onChange={handlePanelPress(urlPanelLabel[`panel_${panelIndex}`])}
                    >

                        <ExpansionPanelSummary className={classes.panelDescription} expandIcon={<ExpandMoreIcon />}>
                            <div classes={classes.catTitle}><h2>{d.category.category_title}</h2>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                {d.category.entries.map((q, index) => (
                                    <div key={uid(q, index)} className={classes.contentQuestion}>
                                        <h3>{ReactHTMLParser(q.entry.question)}</h3>
                                        <div className={classes.contentAnswer}>
                                            <ReactMarkdown
                                                renderers={{
                                                    linkReference: linkOrReference,
                                                }}
                                                source={q.entry.answer}
                                                className={classes.questionAnswer}
                                                escapeHtml
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))}
            </div>
        </>
    );
};

FrequentlyAskedQuestionsUI.propTypes = {
    faqData: shape({
        title: string.isRequired,
        categories: array.isRequired,
        seo: shape({
            page_title: string,
        }),
    }).isRequired,
};
export default FrequentlyAskedQuestionsUI;
