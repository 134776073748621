/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// Libraries
import React from 'react';
import {
    Route, Redirect,
} from 'react-router-dom';
import { string } from 'prop-types';

const getCurrentUrlOrigin = () => {
    if (typeof window !== 'undefined' && window?.location?.href) {
        const url = new URL(window?.location?.origin);
        return url?.origin;
    }
    return null;
};

const getRelativePath = (url) => {
    if (url.includes('https://') || url.includes('http://')) {
        const getUrl = new URL(url);
        return { path: `${getUrl.pathname}${getUrl.search}`, absolute: true, origin: getUrl.origin };
    }
    return { path: url, absolute: false };
};
function RedirectWithStatus({
    to, status, productUnavailableName,
}) {
    return (
        <Route
            render={({ staticContext }) => {
                // there is no `staticContext` on the client, so
                // we need to guard against that here
                if (staticContext) {
                    staticContext.status = status;
                    staticContext.productUnavailableName = productUnavailableName;
                }
                const redirectUrl = getRelativePath(to);
                if (redirectUrl.absolute && getCurrentUrlOrigin() !== redirectUrl.origin && typeof window !== 'undefined') {
                    window.location.href = `${redirectUrl.origin}${redirectUrl.path}`;
                    return null;
                }
                return (
                    redirectUrl?.origin && getCurrentUrlOrigin() !== redirectUrl?.origin ? <Redirect to={`${redirectUrl?.origin}${redirectUrl?.path}`} />
                        : <Redirect to={redirectUrl?.path} />
                );
            }}
        />
    );
}

RedirectWithStatus.propTypes = {
    to: string.isRequired,
    status: string.isRequired,
    productUnavailableName: string,
};

RedirectWithStatus.defaultProps = {
    productUnavailableName: '',
};

export default RedirectWithStatus;
